import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['parentSelect', 'parentIdContainer']
  static values = { parentId: Number }

  parentIdValueChanged(value) {
    this.parentIdContainerTarget.value = value || ''
    this.parentSelectTarget.src = this.#parentSelectUrl
  }

  changeParent({ detail: { id } }) {
    this.parentIdValue = id
  }

  get #parentSelectUrl() {
    const url = new URL(this.parentSelectTarget.src, location)

    url.searchParams.set('parent_id', this.parentIdValue || '')

    return url
  }
}
