import { Controller } from "@hotwired/stimulus"
import { get } from '../../helpers'

export default class extends Controller {
  static targets = ['toggleButton', 'dialog']
  static classes = ['loading']

  static values = {
    editUrl: String,
    id: Number,
    missingDestination: Boolean
  }

  toggle() {
    this.dialogTarget.open ? this.close() : this.open()
  }

  async open() {
    this.dialogTarget.show()
    this.settle()

    this.dialogTarget.classList.add(this.loadingClass)

    try {
      await get(this.editUrlValue)
    } catch {
      this.close()
    }

    this.dialogTarget.classList.remove(this.loadingClass)
  }

  close() {
    this.dialogTarget.close()
  }

  settle() {
    if (!this.dialogTarget.open) return

    const toggleButtonRect = this.toggleButtonTarget.getBoundingClientRect()

    this.dialogTarget.style.left = `${ this.#isMobileView ? 0 : this.#getDialogLeft(toggleButtonRect.left) }px`
    this.dialogTarget.style.top = `${ window.scrollY + toggleButtonRect.bottom }px`
  }

  changeMissingDestination({ detail: { missingDestination } }) {
    this.missingDestinationValue = missingDestination
  }

  #getDialogLeft(left) {
    const elementRect = this.element.getBoundingClientRect()
    const dialogWidth = this.dialogTarget.getBoundingClientRect().width

    if (left + dialogWidth > elementRect.right) {
      return Math.max(elementRect.left, elementRect.right - dialogWidth)
    } else {
      return left
    }
  }

  get #isMobileView() {
    return document.documentElement.hasAttribute('data-mobile-view')
  }
}
