import { Controller } from "@hotwired/stimulus"
import { patch } from '../../../helpers'

export default class extends Controller {
  static targets = ['destinationSelect', 'archived', 'submitButton']

  static values = {
    updateUrl: String,
    destinationId: Number,
    archived: Boolean
  }

  destinationIdValueChanged() {
    this.destinationSelectTarget.src = this.#destinationSelectUrl
  }

  archivedValueChanged(value) {
    this.archivedTarget.checked = value
  }

  changeDestination({ params, detail }) {
    this.destinationIdValue = params.id || detail.id
  }

  changeArchived({ target }) {
    this.archivedValue = target.checked
  }

  async update() {
    let error = false

    this.submitButtonTarget.disabled = true

    try {
      await patch(this.updateUrlValue, { body: this.#updatedParams })
    } catch {
      error = true
    }

    this.submitButtonTarget.disabled = false

    if (error) return

    this.dispatch('update', { detail: { missingDestination: this.#missingDestination } })
  }

  get #destinationSelectUrl() {
    const url = new URL(this.destinationSelectTarget.src, location)

    url.searchParams.set('destination_id', this.destinationIdValue || '')

    return url
  }

  get #updatedParams() {
    return {
      site_category: {
        destination_id: (this.destinationIdValue || null),
        archived: this.archivedValue
      }
    }
  }

  get #missingDestination() {
    return !this.destinationIdValue && !this.archivedValue
  }
}
