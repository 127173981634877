import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { trackingUrl: String }

  #locationParams = new URLSearchParams(location.search)

  connect() {
    this.#setUrlParams()
    this.#dispatchReadyEvent()
  }

  #setUrlParams() {
    const url = new URL(this.element.getAttribute('href'), location)

    url.searchParams.set('path', this.#currentPath)
    this.#locationParams.forEach((value, key) => url.searchParams.set(key, value))

    this.element.setAttribute('href', url)
  }

  #dispatchReadyEvent() {
    this.dispatch('ready', { detail: { trackingUrl: this.trackingUrlValue } })
  }

  get #currentPath() {
    const path = location.pathname.replaceAll('/', '-')

    return (path.length <= 1) ? path : path.slice(1)
  }
}
