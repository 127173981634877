import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['covered', 'caretEllipsis']

  connect() {
    this.uncoverOnWideScreen()
  }

  uncover() {
    this.element.classList.remove(this.coveredClass)

    this.element.querySelectorAll(`.${ this.caretEllipsisClass }`).forEach(el => {
      el.classList.remove(this.caretEllipsisClass)
    })
  }

  uncoverOnWideScreen() {
    if (!document.documentElement.hasAttribute('data-mobile-view')) {
      this.uncover()
    }
  }
}
