import { Controller } from "@hotwired/stimulus"
import { get } from '../../helpers'

export default class extends Controller {
  static values = { url: String }

  async select() {
    this.element.disabled = true

    try {
      await get(this.urlValue)
      this.dispatch('load')
    } catch {
      this.element.disabled = false
    }
  }
}
