import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['autocompleting']

  toggle({ detail: { content } }) {
    content.length > 0 ? this.#show() : this.#hide()
  }

  hideWhenOutside({ target }) {
    if (this.#opening && !this.element.contains(target)) {
      this.#hide()
    }
  }

  #show() {
    this.element.classList.add(this.autocompletingClass)
  }

  #hide() {
    this.element.classList.remove(this.autocompletingClass)
  }

  get #opening() {
    return this.element.classList.contains(this.autocompletingClass)
  }
}
