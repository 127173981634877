import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container']
  static classes = ['tag']

  #trackedUrls = new Set()

  track({ detail: { trackingUrl } }) {
    if (!this.hasContainerTarget || !trackingUrl || this.#trackedUrls.has(trackingUrl)) return

    this.#trackedUrls.add(trackingUrl)
    this.containerTarget.appendChild(this.#createTag(trackingUrl))
  }

  #createTag(url) {
    const tag = document.createElement('img')
    tag.src = url
    tag.alt = ''
    tag.classList.add(this.tagClass)

    return tag
  }
}
