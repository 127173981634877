import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'cancelButton']
  static classes = ['cancelButtonVisible']

  focus() {
    this.inputTarget.focus()
  }

  toggleCancelButton() {
    (this.inputTarget.value !== '') ? this.#showCancelButton() : this.#hideCancelButton()
  }

  hideCancelButtonWhenOutside({ relatedTarget }) {
    if (relatedTarget && this.element.contains(relatedTarget)) return

    this.#hideCancelButton()
  }

  cancelInput() {
    this.inputTarget.value = ''
    this.focus()
  }

  #showCancelButton() {
    this.cancelButtonTarget.classList.add(this.cancelButtonVisibleClass)
  }

  #hideCancelButton() {
    this.cancelButtonTarget.classList.remove(this.cancelButtonVisibleClass)
  }
}
