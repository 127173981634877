import { Controller } from "@hotwired/stimulus"
import { waitForAnimations } from '../helpers'

export default class extends Controller {
  static classes = ['fadeIn']

  connect() {
    if (this.hasFadeInClass) {
      this.#removeFadeInClassAfterAnimations()
    }
  }

  async #removeFadeInClassAfterAnimations() {
    await waitForAnimations(this.element)

    this.element.classList.remove(this.fadeInClass)
  }
}
