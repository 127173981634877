import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['visible']
  static values = {
    waitTime: { type: Number, default: 3000 }
  }

  connect() {
    this.#showLater()
  }

  #showLater() {
    setTimeout(() => {
      this.element.classList.add(this.visibleClass)
    }, this.waitTimeValue)
  }
}
