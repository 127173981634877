import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dateAndButton', 'button', 'container']

  toggle() {
    this.#opening ? this.close() : this.open()
  }

  open() {
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.containerTarget.setAttribute('aria-hidden', 'false')

    this.settle()
  }

  close() {
    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.containerTarget.setAttribute('aria-hidden', 'true')
  }

  closeWhenOutside({ target }) {
    if (!this.#opening) return

    for (const elem of [this.buttonTarget, this.containerTarget]) {
      if (elem.contains(target)) return
    }

    this.close()
  }

  settle() {
    if (!this.#opening) return

    const rect = {
      element: this.element.getBoundingClientRect(),
      dateAndButton: this.dateAndButtonTarget.getBoundingClientRect(),
      container: this.containerTarget.getBoundingClientRect()
    }

    let left = rect.dateAndButton.left

    if (left + rect.container.width > document.body.clientWidth) {
      left = rect.dateAndButton.right - rect.container.width

      if (left < 0) {
        left = rect.element.left
      }
    }

    this.containerTarget.style.left = `${ left }px`
  }

  get #opening() {
    return this.hasContainerTarget && this.containerTarget.getAttribute('aria-hidden') === 'false'
  }
}
