import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce'

export default class extends Controller {
  static targets = ['input', 'list']
  static values = { url: String }

  connect() {
    this.listTarget.addEventListener('turbo:frame-load', this.#dispatchChangeEvent.bind(this))
  }

  searchWithDebounce = debounce(this.#search, 300)

  dispatchFocusEvent() {
    this.#dispatchEvent('focus')
  }

  reset() {
    this.listTarget.innerHTML = ''
    this.#dispatchChangeEvent()
  }

  #search() {
    const query = this.inputTarget.value.trim()

    if (query === '') {
      this.reset()
      return
    }

    const url = new URL(this.urlValue, location)
    url.searchParams.set('q', query)

    this.listTarget.src = url
  }

  #dispatchChangeEvent() {
    this.#dispatchEvent('change')
  }

  #dispatchEvent(name) {
    this.dispatch(name, { detail: { content: this.listTarget.innerHTML } })
  }
}
