import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  jump(event) {
    const destinationElement = this.#getDestinationElement(event.target.href)

    event.preventDefault()
    destinationElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  #getDestinationElement(href) {
    const id = new URL(href, location).hash.slice(1)

    return document.getElementById(id)
  }
}
