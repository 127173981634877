import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    message: { type: String, default: 'よろしいですか？' }
  }

  confirm(event) {
    if (!window.confirm(this.messageValue)) {
      event.stopImmediatePropagation()
      event.preventDefault()
    }
  }
}
