import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'buttonInner', 'content']

  toggle() {
    this.#opening ? this.#close() : this.#open()
  }

  closeWhenOutside({ target }) {
    if (!this.#opening || document.documentElement.hasAttribute('data-mobile-view')) return

    for (const elem of [this.buttonTarget, this.contentTarget]) {
      if (elem.contains(target)) return
    }

    this.#close()
  }

  settle() {
    const rect = this.buttonInnerTarget.getBoundingClientRect()

    this.contentTarget.style.top = `${ window.scrollY + rect.bottom }px`
  }

  #open() {
    this.dispatch('open')
  }

  #close() {
    this.dispatch('close')
  }

  get #opening() {
    return this.contentTarget.getAttribute('aria-hidden') === 'false'
  }
}
