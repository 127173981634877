import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const mql = window.matchMedia(`(min-width: ${ this.#tabletViewMinWidth })`)

    mql.addEventListener('change', this.#onChange)
    this.#onChange(mql)
  }

  #onChange = e => {
    this.#markAsMobileView(!e.matches)

    this.dispatch('change')
  }

  #markAsMobileView(isMobileView) {
    if (isMobileView) {
      this.element.setAttribute('data-mobile-view', '')
    } else {
      this.element.removeAttribute('data-mobile-view')
    }
  }

  get #tabletViewMinWidth() {
    return window.getComputedStyle(document.documentElement).getPropertyValue('--tablet-view-min-width')
  }
}
