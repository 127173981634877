import { FetchRequest } from '@rails/request.js'

function withErrorHandler(request) {
  const notifyError = () => {
    alert("エラーが発生しました。\n\nしばらく時間をおいてから、もう一度お試しください。")
  }

  return request.then(response => {
    return response.ok ? response : Promise.reject()
  }).catch(error => {
    notifyError()
    return Promise.reject(error)
  })
}

export function request(verb, url, options) {
  const request = new FetchRequest(verb, url, { responseKind: 'turbo-stream', ...options })
  return withErrorHandler(request.perform())
}

export function get(url, options) {
  return request('get', url, options)
}

export function post(url, options) {
  return request('post', url, options)
}

export function patch(url, options) {
  return request('patch', url, options)
}

export function destroy(url, options) {
  return request('delete', url, options)
}
