import { Controller } from "@hotwired/stimulus"
import { waitForAnimations } from '../helpers'

export default class extends Controller {
  static targets = ['button', 'content']

  toggle() {
    this.#opening ? this.close() : this.open()
  }

  open() {
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.contentTarget.setAttribute('aria-hidden', 'false')

    this.#animate(0, this.contentTarget.offsetHeight)
  }

  async close() {
    if (this.#animating) return

    await this.#animate(this.contentTarget.offsetHeight, 0)

    this.#closeInnerAccordions()
  }

  #closeInnerAccordions() {
    this.element.querySelectorAll('[aria-expanded="true"]').forEach(e => e.setAttribute('aria-expanded', 'false'))
    this.element.querySelectorAll('[aria-hidden="false"]').forEach(e => e.setAttribute('aria-hidden', 'true'))
  }

  async #animate(from, to) {
    if (this.#prefersReducedMotion) return

    this.contentTarget.style.overflow = 'hidden'

    this.contentTarget.animate([
      { height: `${ from }px` },
      { height: `${ to }px`, easing: 'ease-out' }
    ], this.#animationDuration)

    await waitForAnimations(this.contentTarget)

    this.contentTarget.style.overflow = ''
  }

  get #opening() {
    return this.contentTarget.getAttribute('aria-hidden') === 'false'
  }

  get #animating() {
    return this.contentTarget.getAnimations().length > 0
  }

  get #prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches
  }

  get #animationDuration() {
    const cssValue = window.getComputedStyle(document.documentElement).getPropertyValue('--default-animation-duration')

    return parseInt(cssValue, 10)
  }
}
