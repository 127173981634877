import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ['admin--site-category-editor']

  setArchivedIds() {
    this.#archivableEditorOutlets.forEach(editor => this.#appendIdsField(editor.idValue))
  }

  #appendIdsField(id) {
    const input = document.createElement('input')

    input.type = 'hidden'
    input.name = 'ids[]'
    input.value = id

    this.element.append(input)
  }

  get #archivableEditorOutlets() {
    return this.adminSiteCategoryEditorOutlets.filter(v => v.missingDestinationValue)
  }
}
