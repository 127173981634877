// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails"
import { StreamActions } from '@hotwired/turbo'
import "./controllers"

Turbo.session.drive = false

StreamActions.alert = function() {
  alert(this.getAttribute('message'))
}
