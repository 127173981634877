import { Controller } from "@hotwired/stimulus"
import { request } from '../../helpers'

export default class extends Controller {
  static values = { url: String }

  favorite() {
    this.#request('post')
  }

  unfavorite() {
    this.#request('delete')
  }

  async #request(method) {
    this.element.disabled = true

    try {
      await request(method, this.urlValue)
    } catch {
      this.element.disabled = false
    }
  }
}
