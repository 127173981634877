import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select']

  change({ target }) {
    const id = this.#findSelectedId(target)

    this.dispatch('change', { detail: { id } })
  }

  #findSelectedId(lastSelect) {
    const lastIdx = this.selectTargets.findIndex(v => v === lastSelect)

    let id = null

    for (const select of this.selectTargets.slice(0, lastIdx + 1)) {
      if (select.value === '') return id

      id = select.value
    }

    return id
  }
}
