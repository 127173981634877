import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  #observer

  initialize() {
    this.#observer = new ResizeObserver(this.#resize)
  }

  connect() {
    this.#observer.observe(document.scrollingElement)
  }

  disconnect() {
    this.#observer.unobserve(document.scrollingElement)
  }

  #resize = () => {
    this.dispatch('resize')
  }
}
